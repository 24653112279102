import {ICourierShortResponse} from "../models/Courier";

export const COURIER_TYPE_WALK = 1;
export const COURIER_TYPE_BIKE = 5;
export const COURIER_TYPE_MOTORCYCLE = 8;
export const COURIER_TYPE_AUTO = 10;

export const courierTypeIcons: Record<number, string> = {
  [COURIER_TYPE_WALK]: 'directions_run',
  [COURIER_TYPE_BIKE]: 'directions_bike',
  [COURIER_TYPE_MOTORCYCLE]: 'two_wheeler',
  [COURIER_TYPE_AUTO]: 'directions_car',
}

export const courierTypeYMapsIcons: Record<number, string> = {
  [COURIER_TYPE_WALK]: 'Run',
  [COURIER_TYPE_BIKE]: 'Bicycle2',
  [COURIER_TYPE_MOTORCYCLE]: 'Bicycle',
  [COURIER_TYPE_AUTO]: 'Auto',
}

export const courierTypeYMapsBalloonContents: Record<number, string> = {
  [COURIER_TYPE_WALK]: '(пеший-курьер)',
  [COURIER_TYPE_BIKE]: '(вело-курьер)',
  [COURIER_TYPE_MOTORCYCLE]: '(мото-курьер)',
  [COURIER_TYPE_AUTO]: '(авто-курьер)',
}

/**
 * Тип ставки курьера (1 - старая, 2 - новая)
 */
export enum CourierRateTypes {
  Standard = 1,
  Advanced = 2,
  Premium = 3,
}

type GetCourierNameParams = {
  /** не показывать отчество */
  skipMiddle?: boolean;
}

/**
 * Получить полное имя курьера
 */
export const getCourierName = (
  {
    firstName,
    middleName,
    lastName,
  }: ICourierShortResponse,
  {
    skipMiddle = false,
  }: GetCourierNameParams = {},
) => {
  const nameArray = skipMiddle
    ? [firstName, lastName]
    : [firstName, middleName, lastName];
  
  return nameArray.filter(Boolean).join(' ');
}
